import { ScheduledSessionsTableRow } from "./ScheduledSessionsTableRow";
import { TLTableWithHeader } from "../Table/TLTableWithHeader";

export const ScheduledSessionsCard = ({ data, ...rest }) => {
  if (!data?.length) return null;

  return (
    <TLTableWithHeader
      headerBefore={
        <ScheduledSessionsTableRow
          data={data}
          colSpan={1}
          sx={{ bgcolor: "white" }}
          type="user"
          {...rest}
        />
      }
    />
  );
};
