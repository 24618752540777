import { defineMessages } from "react-intl";
import { Navigate } from "react-router-dom";
import { Layout } from "../../components/Layout";
import { MsgProvider } from "../../components/Msg";
import { useMsg } from "../../components/Msg/Msg";
import { routes } from "../../routes";
import { useUserUpcomingSessionsQuery } from "../Coaches/api";
import { ScheduledSessionsCard } from "../../components/ScheduledSessions/ScheduledSessions";
import { Loaders, QueryRenderer } from "../QM/QueryRenderer";

const messages = defineMessages({
  "myCalendar.title": {
    id: "myCalendar.title",
    defaultMessage: "Calendar",
  },
});

const MyCalendarPageInner = () => {
  const msg = useMsg({ dict: messages });
  const query = useUserUpcomingSessionsQuery({});

  return (
    <Layout header={{ heading: msg("myCalendar.title") }}>
      <QueryRenderer
        loaderEl={<Loaders.CircularBlock spaced />}
        query={query}
        success={({ data, isFetching }) =>
          isFetching ? (
            <Loaders.CircularBlock spaced />
          ) : !data.length ? (
            <Navigate to={routes.dashboard} />
          ) : (
            <ScheduledSessionsCard data={data} />
          )
        }
      />
    </Layout>
  );
};

export const MyCalendarPage = () => {
  return (
    <MsgProvider messages={messages}>
      <MyCalendarPageInner />
    </MsgProvider>
  );
};
