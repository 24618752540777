import { getTimezoneOffset } from "date-fns-tz";
import { formatInTimeZone } from "date-fns-tz/fp";
import { addDays, addSeconds, parseISO } from "date-fns/fp";
import { applySpec, pipe, replace } from "ramda";
import { API_DATETIME_LOCAL_FORMAT } from "./constants";

const getTimezoneShiftString = (dateStr, timeZone) => {
  const date = parseISO(dateStr);
  const offsetMs = getTimezoneOffset(timeZone, date);
  const offset = offsetMs / 60000;
  const sign = offset < 0 ? "-" : "+";
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  const offsetStr = `${sign}${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}`;
  return offsetStr;
};

// this function accepts IANA userTz (Asia/Bangkok) and returns function accepting ISO date
// (e.g. "2025-02-08T00:00:00+07:00" - we assume that date is in browser timezone
// but should be treated as userTz, so browser timezone shift is replaced by userTz shift)
// returns object with UTC "from" and "to" in API_DATETIME_LOCAL_FORMAT,
export const fetchFrameKeyToParams = ({ userTz, intervalDays = 7 }) =>
  pipe(
    // tap(console.log.bind(console, "fetchFrameKeyToParams ISO input")),
    (str) => {
      const offsetStr = getTimezoneShiftString(str, userTz);
      return replace(/\+.{5}$/, offsetStr, str);
    },
    parseISO,
    applySpec({
      from: pipe(formatInTimeZone(API_DATETIME_LOCAL_FORMAT, "UTC")),
      to: pipe(
        addDays(intervalDays),
        addSeconds(-1),
        formatInTimeZone(API_DATETIME_LOCAL_FORMAT, "UTC")
      ),
    })
  );
