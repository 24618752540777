import { defineMessages } from "react-intl";
import { useMsg } from "../../components/Msg/Msg";
import { EmptyTemplate } from "../Feedback/EmptyTemplate";
import { routes } from "../../routes";
import { EmailTo } from "../Authorization/WelcomeScreenTemplate";
import { Layout } from "../../components/Layout";
import { useSearchParams } from "react-router-dom";

const messages = defineMessages({
  "integration-result.success.title": {
    id: "integration-result.success.title",
    defaultMessage: "Success",
  },
  "integration-result.success.description": {
    id: "integration-result.success.description",
    defaultMessage:
      "Your {provider} has been successfully integrated with the TopLeader platform. You can now close this window by clicking the button below.",
  },
  "integration-result.providers.google-calendar": {
    id: "integration-result.providers.google-calendar",
    defaultMessage: "Google Calendar",
  },
  "integration-result.providers.calendly": {
    id: "integration-result.providers.calendly",
    defaultMessage: "Calendly",
  },

  "integration-result.error.title": {
    id: "integration-result.error.title",
    defaultMessage: "Failed",
  },
  "integration-result.error.description": {
    id: "integration-result.error.description",
    defaultMessage:
      "Unfortunately, we were unable to integrate your {provider} with the TopLeader platform at this time. Please try again later. If the issue persists, do not hesitate to contact us at {emailLink} for assistance.",
  },

  "integration-result.back-to-dashboard": {
    id: "integration-result.back-to-dashboard",
    defaultMessage: "Back to dashboard",
  },
  "dict.integration-result.errors.user.email.not.matched": {
    id: "dict.integration-result.errors.user.email.not.matched",
    defaultMessage: "Emails do not match",
  },
  "dict.integration-result.errors.sync.failed": {
    id: "dict.integration-result.errors.sync.failed",
    defaultMessage: "Sync failed",
  },
});
/*
Provide user info that email in topleader do not match with email in calendly
/sync-failure?provider=calendly&error=user.email.not.matched

when sync failed  unknow reason. Some generel info like sunc error etc.
/sync-failure?provider=calendly&error=sync.failed
*/

export function IntegrationResultPage({ result }) {
  const msg = useMsg({ dict: messages });
  let [searchParams] = useSearchParams();
  const isCalendly = searchParams.get("provider") === "calendly";
  const provider = isCalendly
    ? msg("integration-result.providers.calendly")
    : msg("integration-result.providers.google-calendar");
  const errorKeyMaybe = searchParams.get("error");
  const translatedErrorMaybe = errorKeyMaybe
    ? msg.maybe(`dict.integration-result.errors.${errorKeyMaybe}`)
    : undefined;

  if (result === "success")
    return (
      <Layout>
        <EmptyTemplate
          title={msg("integration-result.success.title")}
          description={msg("integration-result.success.description", {
            provider,
          })}
          iconName="ChatBubbleOutlineOutlined"
          button={{
            variant: "contained",
            children: msg("integration-result.back-to-dashboard"),
            href: routes.dashboard,
          }}
        />
      </Layout>
    );

  if (result === "error")
    return (
      <Layout>
        <EmptyTemplate
          title={translatedErrorMaybe || msg("integration-result.error.title")}
          description={msg("integration-result.error.description", {
            provider,
            emailLink: (
              <EmailTo
                email={"support@topleader.io"}
                subject={"Calendar Sync Failed"}
                withIcon={false}
              />
            ),
          })}
          iconName="ChatBubbleOutlineOutlined"
          button={{
            variant: "contained",
            children: msg("integration-result.back-to-dashboard"),
            href: routes.dashboard,
          }}
        />
      </Layout>
    );

  return (
    <Layout>
      <EmptyTemplate
        title={" "}
        iconName="ChatBubbleOutlineOutlined"
        button={{
          variant: "contained",
          children: msg("integration-result.back-to-dashboard"),
          href: routes.dashboard,
        }}
      />
    </Layout>
  );
}
