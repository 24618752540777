import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Paper,
  Snackbar,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { RHFTextField } from "../../components/Forms";
import { Icon } from "../../components/Icon";
import { Msg, useMsg } from "../../components/Msg/Msg";
import { H2, P } from "../../components/Typography";
import { useSendMessageMutation } from "../Messages/api";
import React, { useCallback, useState } from "react";
import { coachesMessages } from "./messages";
import { formatName } from "./coach.utils";

const ContactForm = React.forwardRef(({ onClose, coach }, ref) => {
  const msg = useMsg();
  const { username, firstName, lastName, email } = coach ?? {};
  const methods = useForm({
    mode: "onSubmit",
    // mode: "all",¯
    defaultValues: DEFAULT_VALUES,
  });
  const { reset } = methods;
  const sendMutation = useSendMessageMutation({
    snackbar: {
      success: { message: msg("coaches.contact.success") },
      error: {},
    },
    onSuccess: useCallback(() => {
      reset(DEFAULT_VALUES);
      onClose();
    }, [onClose, reset]),
  });
  const onSubmit = (data, e) => {
    const { subject, message } = data;
    const subjectStr = subject?.trim() ? `[${subject.trim()}] ` : "";
    console.log("[ContactModal.onSubmit]", { data, e, coach, subjectStr });
    return sendMutation.mutateAsync({
      userTo: username,
      messageData: `${subjectStr}${message?.trim()}`,
    });
  };
  const onError = (errors, e) =>
    console.log("[ContactModal.onError]", errors, e);

  return (
    <form onSubmit={methods.handleSubmit(onSubmit, onError)} ref={ref}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", md: "800px" },
          bgcolor: "background.paper",
          borderRadius: "6px",
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: 3,
          // border: "2px solid #000",
          // boxShadow: 24,
        }}
      >
        <FormProvider {...methods}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Avatar sx={{ bgcolor: "#F9FAFB", width: 48, height: 48 }}>
              <Avatar sx={{ bgcolor: "#EAECF0", width: 36, height: 36 }}>
                <Icon name="Mail" sx={{ color: "#667085" }} />
              </Avatar>
            </Avatar>
            <IconButton onClick={onClose}>
              <Icon name="Close" sx={{ color: "#667085" }} />
            </IconButton>
          </Box>
          <H2 id="modal-modal-title">
            <Msg
              id="coaches.contact.title"
              values={{ name: formatName({ firstName, lastName }) }}
            />
          </H2>
          <P id="modal-modal-description">
            <Msg id="coaches.contact.perex" />
          </P>
          {/* <OutlinedField label="Subject" /> */}
          <RHFTextField
            name="subject"
            label={msg("coaches.contact.subject.label")}
            autoFocus
            size="small"
            fullWidth
          />
          <Divider flexItem />
          <RHFTextField
            name="message"
            parametrizedValidate={[
              ["required"],
              ["notBlank"],
              ["minLength", { gteLength: 1 }],
              ["maxLength", { lteLength: 3000 }],
            ]}
            label={msg("coaches.contact.message.label")}
            placeholder={msg("coaches.contact.message.placeholder")}
            size="small"
            hiddenLabel
            multiline
            rows={5}
            sx={{}}
            fullWidth
            displayCharCounter
          />
          <Divider flexItem />
          <Box display="flex" flexDirection="row" gap={3}>
            <Button fullWidth variant="outlined" onClick={() => onClose()}>
              <Msg id="coaches.contact.button.cancel" />
            </Button>
            <Button fullWidth variant="contained" type="submit">
              <Msg id="coaches.contact.button.send" />
            </Button>
          </Box>
        </FormProvider>
      </Paper>
    </form>
  );
});

const DEFAULT_VALUES = {
  subject: "",
  message: "",
};

// TODO: Form reset after close/submit
export const ContactModal = ({ onClose, coach, open = !!coach }) => {
  const msg = useMsg({ dict: coachesMessages });

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContactForm coach={coach} onClose={onClose} />
      </Modal>
    </>
  );
};
