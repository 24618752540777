import { LoadingButton } from "@mui/lab";
import { Box, Button, Skeleton } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { Icon } from "../../components/Icon";
import { Layout } from "../../components/Layout";
import { LinkBehavior } from "../../components/LinkBehavior";
import { MsgProvider } from "../../components/Msg";
import { Msg, useMsg } from "../../components/Msg/Msg";
import { ScheduledSessionsTableRow } from "../../components/ScheduledSessions/ScheduledSessionsTableRow";
import {
  DateCell,
  StyledTableCell,
  StyledTableRow,
  TLCell,
  UserCell,
} from "../../components/Table";
import { TLTableWithHeader } from "../../components/Table/TLTableWithHeader";
import { H2, P } from "../../components/Typography";
import { routes } from "../../routes";
import { formatName } from "../Coaches/coach.utils";
import { ConfirmModal } from "../Modal/ConfirmModal";
import { QueryRenderer } from "../QM/QueryRenderer";
import { SlotChip } from "../Team/Team.page";
import { AddClientModal } from "./AddClientModal";
import {
  useClientsQuery,
  useDeclineMutation,
  useUpcomingCoachSessionsQuery,
} from "./api";
import { clientsMessages } from "./messages";

function ClientsPageInner() {
  const [addMemberVisible, setAddMemberVisible] = useState();
  const [declineMemberVisible, setDeclineMemberVisible] = useState();
  const msg = useMsg({ dict: clientsMessages });

  const query = useClientsQuery();
  const upcomingSessionsQuery = useUpcomingCoachSessionsQuery();
  const declineMutation = useDeclineMutation({
    onSuccess: () => {
      setDeclineMemberVisible();
    },
  });

  const columns = [
    {
      label: msg("clients.table.col.name"),
      key: "name",
      render: (row) => <UserCell email={row.username} name={formatName(row)} />,
    },
    {
      label: msg("clients.table.col.lastSession"),
      key: "lastSession",
      render: (row) => <DateCell utcStr={row.lastSession} />,
    },
    {
      label: msg("clients.table.col.nextSession"),
      key: "nextSession",
      render: (row) => <DateCell utcStr={row.nextSession} />,
    },
    {
      label: msg("clients.table.col.action"),
      key: "action",
      render: (row) => (
        <TLCell>
          <Button
            sx={{ bgcolor: "white" }}
            variant="outlined"
            component={LinkBehavior}
            href={routes.messages}
            state={{ messagesFrom: row.username }}
          >
            {msg("clients.table.action.contact")}
          </Button>
          <Button
            sx={{ ml: 2, bgcolor: "white" }}
            variant="outlined"
            component={LinkBehavior}
            color="error"
            onClick={() => {
              setDeclineMemberVisible(row);
            }}
          >
            {msg("clients.table.action.decline")}
          </Button>
        </TLCell>
      ),
    },
  ];
  const expandedRowRender = useCallback(
    ({ row, columns }) => {
      if (!upcomingSessionsQuery.data) return null;
      // const rowData = upcomingSessionsQuery.data;
      const rowData = upcomingSessionsQuery.data.filter(
        ({ username }) => username === row.username
      );
      return (
        <ScheduledSessionsTableRow
          data={rowData}
          columns={columns}
          name={row.username}
          canCancel
          type="coach"
        />
      );
    },
    [upcomingSessionsQuery.data]
  );
  ConfirmModal.useModal(
    useMemo(
      () => ({
        open: !!declineMemberVisible,
        onClose: () => setDeclineMemberVisible(),
        iconName: "RocketLaunch",
        title: msg("clients.decline.title", {
          name: declineMemberVisible?.name || declineMemberVisible?.username,
        }),
        desc: "",
        error: declineMutation.error,
        sx: { width: "800px" },
        buttons: [
          {
            variant: "outlined",
            type: "button",
            children: msg("clients.decline.no"),
            onClick: () => setDeclineMemberVisible(),
          },
          {
            component: LoadingButton,
            variant: "contained",
            color: "error",
            type: "button",
            children: msg("clients.decline.yes"),
            disabled: declineMutation.isPending,
            loading: declineMutation.isPending,
            onClick: () => declineMutation.mutate(declineMemberVisible),
          },
        ],
      }),
      [declineMemberVisible, declineMutation, msg]
    )
  );

  console.log("[Clients.page]", { query, declineMemberVisible });

  return (
    <Layout header={{ heading: msg("clients.heading") }}>
      <TLTableWithHeader
        headerBefore={
          <QueryRenderer
            loaderEl={
              <StyledTableRow>
                <StyledTableCell colSpan={columns.length}>
                  <Skeleton />
                </StyledTableCell>
              </StyledTableRow>
            }
            query={upcomingSessionsQuery}
            success={({ data }) => (
              <ScheduledSessionsTableRow
                data={data}
                columns={columns}
                type="coach"
              />
            )}
          />
        }
        expandedRowRender={expandedRowRender}
        title={
          <Box sx={{ display: "inline-flex", alignItems: "baseline" }}>
            <H2>
              <Msg id="clients.title" />
            </H2>
            <SlotChip sx={{ display: "inline-flex", p: 0.75, ml: 4 }}>
              <Msg
                id="clients.title.count.badge"
                values={{ count: query.data?.length }}
              />
            </SlotChip>
          </Box>
        }
        subheader={
          <P mt={1.5}>
            <Msg id="clients.sub" />
          </P>
        }
        columns={columns}
        query={query}
        action={
          <Button
            variant="contained"
            startIcon={<Icon name="Add" />}
            aria-label="add member"
            onClick={() => {
              setAddMemberVisible(true);
            }}
          >
            <Msg id="clients.add" />
          </Button>
        }
      />
      <AddClientModal
        open={addMemberVisible}
        onClose={() => setAddMemberVisible(false)}
      />
    </Layout>
  );
}

export function ClientsPage() {
  return (
    <MsgProvider messages={clientsMessages}>
      <ClientsPageInner />
    </MsgProvider>
  );
}
