import { addDays, endOfDay, startOfDay } from "date-fns/fp";
import { pipe } from "ramda";

export const getCalendarInterval = ({
  dateNow,
  offsetDays,
  visibleDaysCount,
}) => {
  return {
    start: pipe(addDays(offsetDays), startOfDay)(dateNow),
    end: pipe(addDays(offsetDays + visibleDaysCount - 1), endOfDay)(dateNow),
  };
};
