import { utcToZonedTime } from "date-fns-tz";
import {
  eachWeekOfIntervalWithOptions,
  formatISO,
  getDay,
  startOfDay,
} from "date-fns/fp";
import { map, pipe } from "ramda";

const getOffsetString = (date) => {
  const offset = date.getTimezoneOffset();
  const sign = offset < 0 ? "+" : "-";
  const hours = Math.abs(Math.floor(offset / 60));
  const minutes = Math.abs(offset % 60);
  return `${sign}${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

// creates an array of floating week keys for the given calendar interval in local (browser) timezone
// weekStartsOn is today, so for one week interval we do just one fetch
export const getFetchFrameKeys = ({
  calendarInterval,
  weekStartsOn = getDay(startOfDay(new Date())),
  timeZone,
}) => {
  const floatingWeekKeys = pipe(
    eachWeekOfIntervalWithOptions({
      weekStartsOn,
    }),
    map(formatISO)
  )(calendarInterval);
  return floatingWeekKeys;
};
