import { parseISO } from "date-fns";
import * as tz from "date-fns-tz";

export const parseLocal = ({
  dateStr,
  timeZone,
  browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone,
}) => {
  if (!dateStr || !timeZone) {
    debugger;
    throw new Error("dateStr or timeZone missing");
  }
  const date = parseISO(dateStr); // new format: "2025-02-10T00:00:00+04:00"
  // const date = tz.zonedTimeToUtc(dateStr, timeZone); // old format without timezone: "2025-02-10T00:00:00"
  // const zonedDate = tz.utcToZonedTime(date, browserTz);
  return date;
  // return zonedDate;
};
