import { useQueries } from "@tanstack/react-query";
import { pipeP } from "composable-fetch";
import { addHours, isWithinInterval } from "date-fns/fp";
import {
  always,
  applySpec,
  filter,
  flatten,
  map,
  path,
  pipe,
  prop,
  tap,
  uniq,
} from "ramda";
import { useContext, useMemo } from "react";
import { useAuth } from "../Authorization";
import { useMyMutation } from "../Authorization/AuthProvider";
import { I18nContext } from "../I18n/I18nProvider";
import { fixEnd } from "../I18n/utils/date";
import { getFetchFrameKeys } from "./getFetchFrameKeys";
import { parseLocal } from "./parseLocal";
import { fetchFrameKeyToParams } from "./fetchFrameKeyToParams";

const handleIntervalOverlapping = ({
  overlapping,
  interval,
  parentInterval,
}) => {
  const msg = `[isIntervalWithin] Overlapping interval ${interval.start} - ${interval.end}`;
  switch (overlapping) {
    case true:
      return true;
    case "throw":
      throw new Error(msg);
    case "debugger":
      debugger;
    // eslint-disable-next-line no-fallthrough
    default:
      console.log(`%c${msg}`, "color:magenta", {
        parentInterval,
        interval,
      });
      return false;
  }
};

export const isIntervalWithin =
  ({ parentInterval, overlapping = "throw" }) =>
  (interval) => {
    const startWithin = isWithinInterval(parentInterval, interval.start);
    const endWithin = isWithinInterval(parentInterval, fixEnd(interval.end));
    if (startWithin !== endWithin) {
      return handleIntervalOverlapping({
        overlapping,
        interval,
        parentInterval,
      });
    }
    return startWithin;
  };

const parseSlot =
  ({ timeZone, browserTz }) =>
  (dateStr) => {
    const start = parseLocal({ timeZone, dateStr, browserTz });
    const end = fixEnd(addHours(1, start));

    return { start, end, originalApiSlot: dateStr };
  };

const fetchAvailabilityWeekIntervals = ({
  authFetch,
  username,
  fetchFrameKey,
  userTz,
  browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone,
}) => {
  return pipeP(
    fetchFrameKeyToParams({ userTz }),
    applySpec({
      url: always(`/api/latest/coaches/${username}/availability`),
      query: {
        username: always(username),
        from: prop("from"),
        to: prop("to"),
      },
    }),
    authFetch,
    map(
      parseSlot({
        timeZone: userTz,
        browserTz,
      })
    )
  )(fetchFrameKey);
};

export const useAvailabilityQueries = ({
  username,
  calendarInterval,
  disabled,
}) => {
  const { authFetch } = useAuth();
  const { userTz } = useContext(I18nContext);
  const fetchFrameKeys = getFetchFrameKeys({
    calendarInterval,
    timeZone: userTz,
  });
  // TODO: frame by current "floating" week, not weekstarts. Initial load - 2x fetch -> 1x fetch
  // console.log(".....", { calendarInterval, fetchFrameKeys });

  const queryDefs = fetchFrameKeys.map((fetchFrameKey) => ({
    enabled: !!username && !disabled,
    queryKey: ["coaches", username, "availability", { userTz, fetchFrameKey }],
    queryFn: async () => ({
      params: { userTz, fetchFrameKey, username, calendarInterval },
      weekData: await fetchAvailabilityWeekIntervals({
        authFetch,
        username,
        fetchFrameKey,
        userTz,
      }),
    }),
  }));

  const queries = useQueries({
    queries: queryDefs,
    combine: (queries) => {
      // react-router > v5, TODO: useMyQuery
      // console.log(">>> COMBINE WORKS", { queries });
      return queries;
    },
  });

  const composedQueries = useMemo(() => {
    const fulfilled = pipe(
      map(path(["data", "weekData"])),
      filter(Boolean) // fulfilled
    )(queries);
    const allIntervalsMaybe =
      fulfilled.length === queries.length
        ? uniq(flatten(fulfilled))
        : undefined;
    const someIntervals = fulfilled.length ? uniq(flatten(fulfilled)) : [];

    return {
      queries,
      allResultsQuery: {
        data: allIntervalsMaybe,
        error: queries.find(({ error }) => error),
        isPending: queries.some(({ isPending }) => isPending),
      },
      someResultsQuery: {
        data: someIntervals,
        error: queries.find(({ error }) => error),
        isPending:
          queries.length && queries.every(({ isPending }) => isPending),
      },
    };
  }, [queries]);

  return composedQueries;
};

export const getIsDayLoading = ({ queries, dayInterval, userTz }) => {
  const fetchFrameKeys = getFetchFrameKeys({
    calendarInterval: dayInterval,
    timeZone: userTz,
  });
  // firstDaysOfTheWeek - one Monday in Europe/Prague can be split in two week starts in UTC
  const allFetched = fetchFrameKeys.every((key) =>
    queries.find((query) => key === query.data?.params?.fetchFrameKey)
  );
  return !allFetched;
};

export const padLeft = (char = "0", num) => {
  const str = `${num}`;
  return str.padStart(2, char);
};

const exampleErr = [
  {
    errorCode: "not.enough.credits",
    fields: [
      {
        name: "user",
        value: "no-credit-user",
      },
    ],
    errorMessage: "User does not have enough credit",
  },
];
export const usePickSlotMutation = ({ username, ...mutationProps }) => {
  const { i18n, userTz } = useContext(I18nContext);
  const pickSlotMutation = useMyMutation({
    fetchDef: {
      method: "POST",
      url: `/api/latest/coaches/${username}/schedule`,
      from: applySpec({
        time: ({ interval, originalApiSlot }) => originalApiSlot,
        // i18n.formatLocal(interval.start, API_DATETIME_LOCAL_FORMAT),
      }),
    },
    snackbar: { success: true, error: true },
    invalidate: [
      { queryKey: ["coaches", username, "availability"] },
      { queryKey: ["user-info"] },
    ],
    ...mutationProps,
  });
  // console.log("[usePickSlotMutation.rndr]", { pickSlotMutation });

  return pickSlotMutation;
};
