import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Paper,
} from "@mui/material";
import { always, map, pipe, prop } from "ramda";
import { useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import stringSimilarity from "string-similarity";
import {
  AutocompleteSelect,
  FreeSoloField,
  LANGUAGE_OPTIONS,
  RHFTextField,
  renderLanguageOption,
} from "../../../components/Forms";
import { RHForm } from "../../../components/Forms/Form";
import { useLoadableOptions } from "../../../components/Forms/hooks";
import { Icon } from "../../../components/Icon";
import { useMsg } from "../../../components/Msg/Msg";
import { H2, P } from "../../../components/Typography";
import { gray500 } from "../../../theme";
import { Authority, useMyMutation } from "../../Authorization/AuthProvider";
import { formatName } from "../../Coaches/coach.utils";
import { INITIAL_FILTER, ratesOptions } from "../../Coaches/CoachesFilter";
import { I18nContext } from "../../I18n/I18nProvider";
import { ConfirmModal } from "../../Modal/ConfirmModal";
import { TIMEZONE_OPTIONS } from "../../Settings/GeneralSettings";
import {
  useAdminUserMutation,
  useCompaniesQuery,
  useCompanyMutation,
} from "./api";
import { generalMessages } from "../../../components/messages";
import { useCoachesQuery } from "../../Coaches/api";
import { certificatesOptions } from "../ProfileSettings";

const USER_STATUS_OPTIONS = [
  { label: "Authorized", value: "AUTHORIZED" },
  { label: "Pending", value: "PENDING" },
  // { label: "Cancelled", value: "CANCELLED" },
];

const PreventSubmission = ({ children }) => {
  const preventHandler = (e) => {
    if (e.key !== "Enter") return;
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      onKeyDown={preventHandler}
      onKeyUp={preventHandler}
      onKeyPress={preventHandler}
      onSubmit={preventHandler}
    >
      {children}
    </div>
  );
};

const ResendInvitationButton = ({ username, locale }) => {
  const generalMsg = useMsg({ dict: generalMessages });
  const mutation = useMyMutation({
    fetchDef: {
      url: `/api/latest/admin/users/${username}/resent-invitation`,
      method: "POST",
      from: always({ locale }),
    },
  });

  const resendConfirmModal = ConfirmModal.useModal(
    useMemo(() => {
      return {
        iconName: "RocketLaunch",
        title: locale ? "Resend invitation link?" : "Language must be selected",
        desc: `username: ${username} language: ${locale}`,
        // error: mutation.error,
        getButtons: ({ onClose }) => [
          {
            variant: "outlined",
            type: "button",
            children: generalMsg("general.cancel"),
            onClick: () => onClose(),
          },
          {
            component: LoadingButton,
            variant: "contained",
            color: "error",
            type: "button",
            children: "OK",
            disabled: mutation.isPending || !locale,
            loading: mutation.isPending,
            onClick: () => mutation.mutateAsync().then(() => onClose()),
          },
        ],
      };
    }, [generalMsg, locale, mutation, username])
  );

  return (
    <Button
      fullWidth
      variant="outlined"
      size="small"
      onClick={resendConfirmModal.show}
      disabled={mutation.isPending}
    >
      Resend invitation link
    </Button>
  );
};

const MemberAdminForm = ({ onClose, initialValues }) => {
  const { userTz } = useContext(I18nContext);
  const msg = useMsg();
  const [createValues, setCreateValues] = useState();

  const coachesQuery = useCoachesQuery({ filter: INITIAL_FILTER() });
  const companiesQuery = useCompaniesQuery();
  const isEdit = !!initialValues && !!Object.values(initialValues).length;
  const mutation = useAdminUserMutation({
    isEdit,
    onSuccess: () => {
      setCreateValues(null);
      onClose();
    },
  });

  const defaultValues = {
    firstName: initialValues.firstName || "",
    lastName: initialValues.lastName || "",
    username: initialValues.username || "",
    authorities: initialValues.authorities?.length
      ? initialValues.authorities
      : ["USER"],
    locale: initialValues.locale,
    timeZone: initialValues.timeZone || userTz,
    companyId: initialValues.companyId || null,
    status: initialValues.status,
    allowedCoachRates: initialValues.allowedCoachRates,
    rate: initialValues.rate,
    certificate: initialValues.certificate,
    ...(isEdit
      ? {
          coach: initialValues.coach,
          credit: initialValues.credit,
          // freeCoach, // TODO?
        }
      : {}),
  };
  const methods = useForm({
    mode: "onSubmit",
    defaultValues,
  });
  const locale = methods.watch("locale");
  const authorities = methods.watch("authorities") || [];
  const isCoach = authorities.includes(Authority.COACH);

  const createCompanyMutation = useCompanyMutation({
    onSuccess: async ({ id }) => {
      const values = { ...createValues, companyId: id };
      await mutation.mutateAsync(values);
      setCreateValues(null);
      // debugger;
    },
  });
  const maybeBestMatchMsg = useMemo(() => {
    if (!createValues) return undefined;
    try {
      const companyNames = companiesQuery.data?.map?.(prop("name"));
      const bestMatch = stringSimilarity.findBestMatch(
        createValues?.companyId || "",
        companyNames || []
      );
      return (
        <P>
          You entered "<b>{createValues?.companyId}</b>", the most similar name
          among existing companies: "<b>{bestMatch.bestMatch.target}</b>"
          (similarity score: {bestMatch.bestMatch.rating.toFixed(2)})
        </P>
      );
    } catch (e) {
      console.error(e);
      debugger;
      return undefined;
    }
  }, [companiesQuery.data, createValues]);
  ConfirmModal.useModal(
    useMemo(
      () => ({
        open: !!createValues,
        onClose: () => setCreateValues(null),
        iconName: "RocketLaunch",
        title: "Create new company?",
        desc: maybeBestMatchMsg || "",
        // error: createCompanyMutation.error,
        getButtons: ({ onClose }) => [
          {
            variant: "outlined",
            type: "button",
            children: "Cancel",
            onClick: () => onClose(),
          },
          {
            component: LoadingButton,
            variant: "contained",
            type: "button",
            children: "These two are different companies, create new",
            disabled: createCompanyMutation.isPending,
            loading: createCompanyMutation.isPending,
            onClick: () =>
              createCompanyMutation.mutate({ name: createValues?.companyId }),
          },
        ],
      }),
      [createCompanyMutation, createValues, maybeBestMatchMsg]
    )
  );

  const onSubmit = async (values, e) => {
    if (typeof values.companyId === "string") {
      setCreateValues(values);
    } else {
      mutation.mutateAsync(values);
    }
  };
  const onError = (errors, e) => console.log("[modal.onError]", errors, e);

  const companyOptionsProps = useLoadableOptions({
    query: companiesQuery,
    map: useCompaniesQuery.toOpts,
  });
  const coachOptionsProps = useLoadableOptions({
    query: coachesQuery,
    map: pipe(
      prop("content"),
      map((coach) => ({
        value: coach.username,
        label: `${formatName(coach)} (${coach.username})`,
      }))
    ),
  });

  return (
    <RHForm form={methods} onSubmit={onSubmit} onError={onError}>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Avatar sx={{ bgcolor: "#F9FAFB", width: 48, height: 48 }}>
            <Avatar sx={{ bgcolor: "#EAECF0", width: 36, height: 36 }}>
              <Icon name="GroupAdd" sx={{ color: gray500 }} />
            </Avatar>
          </Avatar>
          <IconButton onClick={onClose}>
            <Icon name="Close" sx={{ color: gray500 }} />
          </IconButton>
        </Box>
        <H2 id="add-member-modal-title">
          {msg(
            isEdit
              ? "settings.admin.member.modal.title.edit"
              : "settings.admin.member.modal.title"
          )}
        </H2>
        <P id="add-member-modal-description">
          {msg(
            isEdit
              ? "settings.admin.member.modal.desc.edit"
              : "settings.admin.member.modal.desc"
          )}
        </P>
        <RHFTextField
          name="firstName"
          label={msg("settings.admin.member.modal.fields.firstName")}
          rules={{ required: true, minLength: 2 }}
          autoFocus
          fullWidth
        />
        <RHFTextField
          name="lastName"
          label={msg("settings.admin.member.modal.fields.lastName")}
          rules={{ required: true, minLength: 2 }}
          fullWidth
        />
        <RHFTextField
          name="username"
          label={msg("settings.admin.member.modal.fields.username")}
          rules={{ required: true, minLength: 2 }}
          fullWidth
          disabled={isEdit}
        />
        <PreventSubmission>
          <FreeSoloField
            name="companyId"
            label={msg("settings.admin.member.modal.fields.companyId")}
            fullWidth
            {...companyOptionsProps}
          />
        </PreventSubmission>
        <AutocompleteSelect
          multiple
          name="authorities"
          label={msg("settings.admin.member.modal.fields.authorities")}
          options={Object.values(Authority).map((value) => ({
            value,
            label: value,
          }))}
        />
        {isEdit ? (
          <AutocompleteSelect
            name="coach"
            label={msg("settings.admin.member.modal.fields.coach")}
            {...coachOptionsProps}
          />
        ) : null}
        {isEdit ? (
          <RHFTextField
            name="credit"
            label={msg("settings.admin.member.modal.fields.credit")}
            rules={{}}
            fullWidth
          />
        ) : null}
        <AutocompleteSelect
          name="status"
          label={msg("settings.admin.member.modal.fields.status")}
          rules={{ required: true }}
          options={USER_STATUS_OPTIONS}
        />
        <AutocompleteSelect
          name="locale"
          label={msg("settings.admin.member.modal.fields.locale")}
          options={LANGUAGE_OPTIONS}
          renderOption={renderLanguageOption}
          parametrizedValidate={[["required"]]}
        />
        <AutocompleteSelect
          name="timeZone"
          label={msg("settings.admin.member.modal.fields.timeZone")}
          options={TIMEZONE_OPTIONS}
          parametrizedValidate={[["required"]]}
        />
        <AutocompleteSelect
          multiple
          name="allowedCoachRates"
          label={msg("settings.admin.member.modal.fields.allowedCoachRates")}
          options={ratesOptions}
        />
        {isCoach ? (
          <AutocompleteSelect
            name="rate"
            label={msg("settings.admin.member.modal.fields.rate")}
            options={ratesOptions}
          />
        ) : null}
        {isCoach ? (
          <AutocompleteSelect
            name="certificate"
            label={msg("settings.admin.member.modal.fields.certificate")}
            options={certificatesOptions}
          />
        ) : null}
        {isEdit && (
          <ResendInvitationButton
            username={initialValues.username}
            locale={locale}
          />
        )}

        <Divider flexItem sx={{ mt: 3 }} />
        <Box display="flex" flexDirection="row" gap={3}>
          <Button fullWidth variant="outlined" onClick={() => onClose()}>
            {msg("settings.admin.member.cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            type="submit"
            disabled={mutation.isPending}
          >
            {msg(
              isEdit
                ? "settings.admin.member.submit.edit"
                : "settings.admin.member.submit.new"
            )}
          </Button>
        </Box>
        {/* </FormProvider> */}
      </Paper>
    </RHForm>
  );
};

export const MemberAdminModal = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="add-member-modal-title"
      aria-describedby="add-member-modal-description"
    >
      <MemberAdminForm {...props} />
    </Dialog>
  );
};
