import { format, parse } from "date-fns";
import { chain } from "ramda";
import { INDEX_TO_DAY } from "./constants";

export const splitInterval = ({ from, to }) => {
  const fromDayIndex = INDEX_TO_DAY.indexOf(from.day);
  const toDayIndex = INDEX_TO_DAY.indexOf(to.day);
  const fromTime = parse(from.time, "HH:mm:ss", new Date());
  const toTime = parse(to.time, "HH:mm:ss", new Date());

  if (fromDayIndex === toDayIndex) {
    return [{ from, to }];
  }

  let intervals = [];
  let currentDayIndex = fromDayIndex;
  let currentFromTime = fromTime;

  while (currentDayIndex !== toDayIndex) {
    const dayName = INDEX_TO_DAY[currentDayIndex];
    intervals.push({
      from: { day: dayName, time: format(currentFromTime, "HH:mm:ss") },
      to: { day: dayName, time: "23:59:59" },
    });

    currentDayIndex = (currentDayIndex + 1) % 7;
    currentFromTime = parse("00:00:00", "HH:mm:ss", new Date());
  }

  intervals.push({
    from: { day: INDEX_TO_DAY[toDayIndex], time: "00:00:00" },
    to: { day: INDEX_TO_DAY[toDayIndex], time: format(toTime, "HH:mm:ss") },
  });

  return intervals;
};

export const normalizeIntervals = chain(splitInterval);
