import { LoadingButton } from "@mui/lab";
import { Tooltip } from "@mui/material";
import { useContext, useMemo } from "react";
import { useDeclineSessionMutation } from "../../features/Clients/api";
import { clientsMessages } from "../../features/Clients/messages";
import { formatName } from "../../features/Coaches/coach.utils";
import { I18nContext } from "../../features/I18n/I18nProvider";
import { ConfirmModal } from "../../features/Modal/ConfirmModal";
import { useMsg } from "../Msg/Msg";

export const CancelSessionButton = ({ data, canCancel, type }) => {
  const { i18n } = useContext(I18nContext);
  const msg = useMsg({ dict: clientsMessages });
  const {
    time,
    username,
    firstName,
    lastName,
    name = formatName({ firstName, lastName }),
  } = data;
  const parsed = i18n.parseUTCLocal(time);
  const _declineSessionMutation = useDeclineSessionMutation({ type });
  const cancelMutationMaybe = canCancel ? _declineSessionMutation : undefined;

  const { show: showConfirmCancel } = ConfirmModal.useModal(
    useMemo(() => {
      return {
        iconName: "RocketLaunch",
        title:
          name || username
            ? msg("clients.upcoming.decline-session.confirm.title", {
                date: i18n.formatLocal(parsed, "Ppppp"),
                name,
                username,
              })
            : msg("clients.upcoming.decline-private-session.confirm.title", {
                date: i18n.formatLocal(parsed, "Ppppp"),
              }),
        desc: "",
        // error: cancelMutationMaybe.error,
        getButtons: ({ onClose }) => [
          {
            component: LoadingButton,
            variant: "contained",
            color: "error",
            type: "button",
            children: msg("clients.upcoming.decline-session.confirm.decline"),
            disabled: cancelMutationMaybe.isPending,
            loading: cancelMutationMaybe.isPending,
            onClick: () => cancelMutationMaybe.mutate(data),
          },
          {
            variant: "contained",
            type: "button",
            children: msg("clients.upcoming.decline-session.confirm.keep"),
            onClick: () => onClose(),
          },
        ],
      };
    }, [cancelMutationMaybe, data, i18n, msg, name, parsed, username])
  );

  const renderCancelButton = () => {
    if (!cancelMutationMaybe) return null;
    return (
      <LoadingButton
        // size="small"
        sx={{ ml: 2, bgcolor: "white" }}
        variant="outlined"
        color="error"
        onClick={() => showConfirmCancel()}
        loading={cancelMutationMaybe.isPending}
      >
        {msg("clients.upcoming.decline-session")}
      </LoadingButton>
    );
  };

  return cancelMutationMaybe?.error ? (
    <Tooltip title={cancelMutationMaybe.error?.message} placement="top">
      {renderCancelButton()}
    </Tooltip>
  ) : (
    renderCancelButton()
  );
};
