import { Box } from "@mui/material";
import { useContext } from "react";
import { formatName } from "../../features/Coaches/coach.utils";
import { I18nContext } from "../../features/I18n/I18nProvider";
import { gray500, gray900 } from "../../theme";
import { generalMessages } from "../messages";
import { useMsg } from "../Msg/Msg";
import { P } from "../Typography";
import { CancelSessionButton } from "./CancelSessionButton";

export const ScheduledSession = ({
  data,
  canCancel,
  type, // "coach" || "user"
  extraInfo,
}) => {
  const {
    time,
    username,
    firstName,
    lastName,
    name = formatName({ firstName, lastName }),
  } = data;
  const { i18n } = useContext(I18nContext);
  const parsed = i18n.parseUTCLocal(time);
  const generalMsg = useMsg({ dict: generalMessages });

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          py: 2,
          gap: 2,
          borderBottom: `1px solid #EAECF0`,
          "&:last-child": { borderBottom: 0 },
        }}
      >
        <Box display="flex" gap={1}>
          <P sx={{ fontSize: 18, color: gray900, fontWeight: 600 }}>
            {i18n.formatLocal(parsed, "PP")}
          </P>
          <P sx={{ fontSize: 18, color: gray500, fontWeight: 400 }}>
            {i18n.formatLocal(parsed, "ccc")}
          </P>
        </Box>
        <Box display="flex" gap={1}>
          <P sx={{ fontSize: 18, color: gray900, fontWeight: 600 }}>
            {i18n.formatLocal(parsed, "pppp")}
          </P>
          <P sx={{ fontSize: 18, color: gray900, fontWeight: 400 }}>
            {name || username || generalMsg("general.private")}
          </P>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            flexGrow: 2,
          }}
        >
          <CancelSessionButton data={data} canCancel={canCancel} type={type} />
        </Box>
      </Box>
      {extraInfo && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            py: 1,
          }}
        >
          {extraInfo}
        </Box>
      )}
    </Box>
  );
};
