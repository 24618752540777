import { Box, Chip, Skeleton } from "@mui/material";
import { getCoachLanguagesOptions, getLabel } from "../../components/Forms";
import { useMsg } from "../../components/Msg/Msg";
import { ShowMore } from "../../components/ShowMore";
import { H1, P } from "../../components/Typography";
import {
  LinkedInProfileMaybe,
  certificatesOptions,
} from "../Settings/ProfileSettings";
import { useFieldsDict } from "../Settings/useFieldsDict";
import { FavoriteButton } from "./Favorites/FavoriteButton";
import { coachesMessages } from "./messages";
import { isProduction } from "../../utils/environment";

export const CoachInfo = ({
  coach,
  withFavorite,
  maxBioChars = 50,
  isLoading,
  sx = {},
}) => {
  const msg = useMsg({ dict: coachesMessages });
  const { fieldsOptions } = useFieldsDict();
  const {
    name,
    experience,
    languages,
    certificate,
    bio,
    fields,
    linkedinProfile,
    username,
  } = coach ?? {};

  console.log(username, { coach });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        ...sx,
      }}
    >
      {isLoading ? (
        <Box sx={{ width: "100%" }}>
          <Skeleton variant="text" sx={{ height: 17, width: "60%" }} />
          <Skeleton variant="text" sx={{ width: "100%" }} />
          <Skeleton variant="text" sx={{ width: "100%" }} />
          <Skeleton variant="text" sx={{ width: "40%" }} />
        </Box>
      ) : (
        <>
          <H1
            title={!isProduction() ? username : undefined}
            gutterBottom
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: undefined,
            }}
          >
            {name}
            {!withFavorite ? null : <FavoriteButton username={username} />}
          </H1>
          <P gutterBottom>{getLabel(certificatesOptions)(certificate)}</P>
          <P gutterBottom>{msg("coaches.coach.experience", { experience })}</P>
          <P gutterBottom>
            {msg("coaches.coach.languages")}
            {": "}
            {languages.map(getLabel(getCoachLanguagesOptions())).join(", ")}
          </P>
          <P
            emphasized
            gutterBottom
            sx={{ fontSize: 14, my: 2, whiteSpace: "normal" }}
          >
            <ShowMore
              maxChars={maxBioChars}
              text={bio}
              moreTranslation={msg("coaches.coach.show-more")}
            />
          </P>
          <Box flex="1 1 auto" display="flex" />
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            {fields.map(getLabel(fieldsOptions)).map((label) => (
              <Chip
                key={label}
                sx={{ borderRadius: "6px", bgcolor: "#F9F8FF" }}
                label={label}
              />
            ))}
          </Box>
          <LinkedInProfileMaybe href={linkedinProfile} />
        </>
      )}
    </Box>
  );
};
