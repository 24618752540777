import { clientsMessages } from "../../features/Clients/messages";
import { gray50 } from "../../theme";
import { useMsg } from "../Msg/Msg";
import { StyledTableCell, StyledTableRow } from "../Table";
import { H2 } from "../Typography";
import { ScheduledSession } from "./ScheduledSession";

export const ScheduledSessionsTableRow = ({
  data,
  columns,
  colSpan = columns.length,
  name,
  canCancel = true,
  type, // "coach" || "user"
  firstRowExtraInfo,
  sx = {},
}) => {
  const msg = useMsg({ dict: clientsMessages });

  if (!data?.length) return null;

  return (
    <StyledTableRow
      sx={{ bgcolor: gray50, ...sx }}
      //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <StyledTableCell colSpan={colSpan} variant="default">
        <H2 sx={{ fontSize: "16px" }}>
          {name
            ? msg("clients.upcoming.with-name", { name })
            : msg("clients.upcoming.all-sessions")}
        </H2>
        {data.map((session, i) => (
          <ScheduledSession
            key={session.time + session.username}
            data={session}
            canCancel={canCancel}
            type={type}
            extraInfo={i === 0 ? firstRowExtraInfo : undefined}
          />
        ))}
      </StyledTableCell>
    </StyledTableRow>
  );
};
